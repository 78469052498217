<template>
  <div class="panel">
    <div class="panel-item">
      <header>Нэвтрэх</header>
    </div>
    <div class="panel-item">
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        ref="form"
      >
        <el-form-item prop="username" label="Нэвтрэх нэр:">
          <el-input
            v-model="model.username"
            placeholder="Нэвтрэх нэр"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="Нууц үг:">
          <el-input
            v-model="model.password"
            placeholder="Нууц үг"
            type="password"
            prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <div class="mb20">
          <router-link to="/forgot-password">Нууц үгээ мартсан</router-link>
        </div>
        <div>
          <el-button
            :loading="loading"
            class="block"
            type="primary"
            native-type="submit"
            @click="onSubmit()"
            >Нэвтрэх</el-button
            >
        </div>
        <div class="mt20 text-center">
          <router-link class="inventory" to="/signUp">Бүртгүүлэх</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { signIn } from '../../../utils/auth.js'
export default {
  name: 'login',
  data () {
    return {
      validCredentials: {
        username: 'lightscope',
        password: 'lightscope'
      },
      model: {
        username: '',
        password: ''
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: 'Username is required',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' },
          {
            min: 5,
            message: 'Password length should be at least 5 characters',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          signIn(this.model.username, this.model.password).then((response) => {
            this.loading = false
          })
        } else {
          return null
        }
      })
    }
  }
}
</script>
